<template>
  <div class="footer">
    <div class="footer-info">
<!--      <div class="footer-info-tag">-->
<!--        <div class="box">-->
<!--          <div>帮助中心</div>-->
<!--          <div class="tips-box">-->
<!--            <div>使用指引</div>-->
<!--            <div>服务协议</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="box">-->
<!--          <div>关注我们</div>-->
<!--          <div class="tips-box">-->
<!--            <div>公司介绍</div>-->
<!--            <div>加入我们</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="box">-->
<!--          <div>关注我们</div>-->
<!--          <div class="tips-box">-->
<!--            <div>联系我们</div>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
      <div class="footer-info-content">
        <div class="text">
          <div class="text-title">公司名称：</div>
          <div class="text-info">贵州金遵景区管理有限公司</div>
        </div>
        <div class="text">
          <div class="text-title" style="word-spacing:30px">邮  编：</div>
          <div class="text-info">563000</div>
        </div>
        <div class="text">
          <div class="text-title">公司地址：</div>
          <div class="text-info">贵州省遵义市新蒲新区礼仪街道高铁新城新南大道遵义交旅投大厦7楼</div>
        </div>
      </div>
    </div>
    <div class="footer-copy">
      <div class="footer-copy-number">
        <img src="@/assets/image/footer/national-emblem.png" alt="">
        <a href=" " rel="noreferrer" target="_blank">贵公网安备52038102052702</a >
      </div>
      <div class="footer-copy-number">copyright@2023 贵州金遵景区管理有限公司 黔ICP备19009717号-1</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "FooterView",
  components: {

  },
  computed: {

  },
  data() {
    return {

    };
  },
  mounted() {},
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.footer{
  width: 100%;
  height: 235px;
  &-info{
    height: 170px;
    background: #2E2E2E;
    padding: 0 148px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    &-tag{
      width: 800px;
      height: 100px;
      display: flex;
      flex-direction: row;
      padding-top: 43px;
      gap: 54px;
     .box{
       display: flex;
       flex-direction: column;
       font-size: 20px;
       font-weight: 400;
       color: #FFFFFF;
       gap: 13px;

       .tips-box{
         display: flex;
         flex-direction: row;
         font-size: 16px;
         font-weight: 400;
         color: #999999;
         gap: 15px;
       }
     }
    }
    &-content{
      width: 600px;
      height: 100px;
      margin-top: 43px;
      .text{
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(255,255,255,0.4);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .text-title {
          width: 100px;
          color: rgba(255,255,255,0.8);
        }
        .text-info {
          width: 400px;
        }
      }
    }

  }
  &-copy{
    height: 65px;
    background: #444445;
    color: #999999;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 148px;
    &-number{

      img{
        width: 16px;
        height: 14px;
      }
    }
  }
}
</style>